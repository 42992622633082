import React from "react";
import {
  Box, Button, Flex, HStack, IconButton, Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Icon,
  chakra,
  CloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent
} from "@chakra-ui/react";
import { HiOutlineSun, HiOutlineMoon, HiOutlineHome, HiMenu } from "react-icons/hi";
import { Link } from 'react-router-dom'
import Logo from './../assets/logo.png'
import { useViewportScroll } from "framer-motion";
const Footer = () => {
  const { toggleColorMode: toggleMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(HiOutlineMoon, HiOutlineSun);
  const bg = useColorModeValue("white", "gray.800");
  const ref = React.useRef(null);
  const [y, setY] = React.useState(0);
  const height = ref.current ? ref.current.getBoundingClientRect() : 0;
  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);
  const cl = useColorModeValue("gray.800", "white");
  const mobileNav = useDisclosure();

  const Section = (props) => {
    const ic = useColorModeValue("brand.600", "brand.50");
    const hbg = useColorModeValue("gray.50", "brand.400");
    const tcl = useColorModeValue("gray.900", "gray.50");
    const dcl = useColorModeValue("gray.500", "gray.50");
    return (
      <Link
        m={-3}
        p={3}
        display="flex"
        alignItems="start"
        rounded="lg"
        _hover={{
          bg: hbg,
        }}
      >
        <Icon
          flexShrink={0}
          h={6}
          w={6}
          color={ic}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d={props.icon} />
        </Icon>
        <Box ml={4}>
          <chakra.p fontSize="sm" fontWeight="700" color={tcl}>
            {props.title}
          </chakra.p>
          <chakra.p mt={1} fontSize="sm" color={dcl}>
            {props.children}
          </chakra.p>
        </Box>
      </Link>
    );
  };
  return (
    <chakra.header
      ref={ref}
      shadow={y > height ? "sm" : undefined}
      transition="box-shadow 0.2s"
      bg={bg}
      borderBottom="6px solid"
      borderBottomColor="brand.400"
      w="full"
      overflowY="hidden"
      borderTopWidth={2}
      color="gray.200"
      _dark={{
        color: "gray.900",
      }}
    >
      <chakra.div minH="max-content" mx="auto" maxW="1200px"
        py={5}
      >
        <Flex
          w="full"
          h="full"
          px="6"
          alignItems="center"
          justifyContent="space-between"
          direction={{
            base: 'column',
            md: 'row'
          }}
        >
          <Flex align="flex-start">
            <Flex
              direction={{
                base: 'column',
                md: 'row'
              }}
            >
              <Link href="/" >
                <Image src={Logo} mr={3} />
              </Link>

              <Button variant="link"
                as={Link}
                size='md'
                to='/terms-of-service'
              >
                Terms of service
              </Button>
              <Button variant="link"
                as={Link}
                size='md'
                to='/privacy-policy'
                ml={{
                  base: 0,
                  md: 3
                }}
                textDecoration='none'
              >
                Privacy policy
              </Button>
            </Flex>
          </Flex>
          <Flex justify="flex-end" align="center" color="gray.400">
            <HStack
              spacing="5"
            >
              <Button colorScheme="gray" variant="ghost" size="sm">
                @BreezyShare {new Date().getFullYear()}
              </Button>
            </HStack>
          </Flex>
        </Flex>
      </chakra.div>
    </chakra.header>
  );
};


export default Footer;