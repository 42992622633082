import React from 'react'
import { Box, FormControl, HStack, Select } from '@chakra-ui/react';
const Filters = ({
  setFilter, filter
}) => {
  return (<Box mt={5}>
    <HStack>
      <FormControl>
        <Select id='date' placeholder='Date' size='sm'
          onChange={e => setFilter({ ...filter, date: e.target.value })}
        >
          <option value='desc'>New</option>
          <option value='asc'>Old</option>
        </Select>
      </FormControl>
      <FormControl>
        <Select id='Visitors' placeholder='Visitors' size='sm' width='12ch'
          onChange={e => setFilter({ ...filter, visitors: e.target.value })}
        >
          <option value='desc'>Most</option>
          <option value='asc'>Least</option>
        </Select>
      </FormControl>
    </HStack>
  </Box>);
}

export default Filters;