import React from "react";
import { Box, Button, Flex, Stack, chakra } from "@chakra-ui/react";
import { Link } from 'react-router-dom'
import { UserContext } from "../../Helper/UserContext";

const CTA = () => {
  const { isAuth } = React.useContext(UserContext)

  return (
    <Flex
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      p={{
        base: 30,
        md: 50
      }}
      w="full"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        justify="center"
        bg="white"
        _dark={{
          bg: "gray.800",
        }}
        w="full"
      >
        <Box
          w={{
            base: "full",
            md: "75%",
            lg: "50%",
          }}
          px={4}
          py={20}
          textAlign={{
            base: "left",
            md: "center",
          }}
        >
          <chakra.span
            fontSize={{
              base: "2xl",
              sm: "4xl",
            }}
            fontWeight="extrabold"
            letterSpacing="tight"
            lineHeight="shorter"
            color="gray.900"
            _dark={{
              color: "gray.100",
            }}
            mb={6}
            textAlign='center'
          >
            <chakra.span display="block">Ready to Embrace <br />the Breeze of Ephemeral Sharing?</chakra.span>
          </chakra.span>
          <Stack
            justifyContent={{
              base: "left",
              md: "center",
            }}
            direction={{
              base: "column",
              sm: "row",
            }}
            spacing={2}
            mt={2}
          >
            <Button display="inline-flex" rounded="md" shadow="md"
              colorScheme='blue'
              as={Link}
              to={!isAuth ? '/auth' : '/dashboard'}
            >
              Get started
            </Button>
          </Stack>
        </Box>
      </Flex>
    </Flex>
  );
}

export default CTA;