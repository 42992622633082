import React from "react";
import {
  Box, FormControl, GridItem, Heading, SimpleGrid, Stack,
  InputGroup, Text, chakra,
  InputLeftAddon, Input, FormLabel, Textarea, Button, Divider,
  VisuallyHidden,
  FormHelperText, Flex, Avatar, Icon, Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  CircularProgress,
  CircularProgressLabel,
  useToast,
  Img
} from "@chakra-ui/react";
import { Link } from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from '@firebase/storage'
import {
  collection, orderBy, query, limit, getDocs, startAfter, endBefore,
  addDoc, Timestamp
} from "firebase/firestore";
import { storage, db } from './../../Helper/firebase-config'
import { UserContext } from './../../Helper/UserContext'
import Success from './../../assets/success.svg'
const NewContent = () => {
  const toast = useToast()
  const { user } = React.useContext(UserContext)

  const [content, setContent] = React.useState({
    title: '',
    description: '',
    type: 'image',
    expiration: 12
  })
  const [mediaUrl, setMediaUrl] = React.useState('')
  const [previewFile, setPreviewFile] = React.useState('')
  const [finalContentUrl, setFinalContentUrl] = React.useState('')
  const [isUploadingFile, setIsUploadingFile] = React.useState(false)
  const [progress, setProgress] = React.useState(0)
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false)
  const [contentAdded, setContentAdded] = React.useState(false)

  function copyToClipboard(text) {
    // Create a textarea element to hold the text
    const textarea = document.createElement('textarea');

    // Set the text content to the provided text
    textarea.value = text;

    // Make the textarea hidden
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    textarea.style.opacity = 0;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Execute the copy command
      document.execCommand('copy');
      toast({
        title: 'Copied to clipboard!',
        status: 'success',
        duration: 3500,
        isClosable: true,
      })
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    } finally {
      // Remove the textarea from the document
      document.body.removeChild(textarea);
    }
  }



  const sanitizedForm = (content, type) => {

    let isValid = true
    let message = null
    if (type == 'preview') message = "We could not generate a preview link"
    else if (type == 'save') message = "We could not save the content"
    let values = Object.values(content)
    values.forEach(value => {
      if (!value.toString().length) isValid = false
    })
    if (!isValid) {
      toast({
        title: 'Incomplete information',
        description: message,
        status: 'error',
        duration: 3500,
        isClosable: true,
      })
    }
    return isValid
  }
  const addContent = async (content) => {

    if (!sanitizedForm(content, 'save')) return

    setIsButtonDisabled(true)
    const contentId = Math.random().toString(36).slice(2, 6)
    let currentDate = new Date();
    let expired_at = currentDate.setHours(currentDate.getHours() + content.expiration);
    const collectionRef = collection(db, 'media');
    const docData = {
      ...content,
      visitors: 0,
      media: mediaUrl,
      created_at: Timestamp.fromDate(new Date()),
      expired_at,
      owner: user.uid,
      contentId
    }
    await addDoc(collectionRef, docData)
      .then(() => {
        setContentAdded(true)
        let contentUrl = `${window.location.origin}/content/${contentId}`
        setFinalContentUrl(contentUrl)
      })
      .catch(err => console.log(err))

    setIsButtonDisabled(false)
    return
  }

  const redirectToPreview = (content) => {

    if (!sanitizedForm(content, 'preview')) return
    let keys = Object.keys(content)
    let tempQueryString = ''

    keys.forEach(key => {
      tempQueryString += `${key}=${content[key]}&`
    })

    let finalQueryString = `/preview?${tempQueryString.slice(0, tempQueryString.length - 1)}`
    window.open(finalQueryString, "_blank");
    return
  }

  const getAllowedMimes = (type) => {
    switch (type) {
      case 'image':
        return 'image/jpeg,image/png'
        break
      case 'video':
        return 'video/mp4'
        break
      case 'pdf':
        return 'application/pdf'
        break
      default:
        return 'image/jpeg,image/png'
    }
  }

  const deleteUrl = (url) => {
    const urlRef = ref(storage, url)
    return deleteObject(urlRef)
  }

  const uploadFile = (file) => {
    // if images file does not exist : exit from function
    if (!file) return
    let fileName = `${Math.random().toString(36).slice(2, 12)}.${file.name.split('.')[1]}`
    const storageRef = ref(storage, `/media/${fileName}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on('state_changed', snapshot => {
      setIsUploadingFile(true)
      const { bytesTransferred, totalBytes } = snapshot
      const prog = Math.round((bytesTransferred / totalBytes) * 100)
      setProgress(prog)
    }, err => console.log("error occured while uploading" + err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref)
          .then(url => {
            setMediaUrl(url.toString())
          })
      }
    )

    return
  }
  const onDrop = React.useCallback(async (acceptedFiles, rejectedFiles) => {
    let file = acceptedFiles[0]
    setPreviewFile(`- ${file.path}`)

    uploadFile(file)

  }, [])

  const dropParams = {
    accept: getAllowedMimes(content.type),
    maxFiles: 1,
    maxSize: 1000000
  }

  const { getInputProps, getRootProps, isDragActive } = useDropzone({ onDrop, ...dropParams })

  const getExpiration = value => {
    switch (value) {
      case 0:
        return 6
        break
      case 25:
        return 12
        break
      case 50:
        return 24
        break
      case 75:
        return 48
        break
    }
  }
  const changeContent = (field, isExpiration = false) => {
    if (isExpiration) {
      setContent({ ...content, expiration: getExpiration(field) })
    } else setContent({ ...content, ...field })
  }


  return (
    <>
      {contentAdded && <Box
        bg="#edf3f8"
        _dark={{
          bg: "#111",
        }}
        p={10}
      >
        <Flex
          direction='column'
          align='center'
        >
          <Flex
            bg="white"
            _dark={{
              bg: "#141517",
            }}
            p={{
              base: 6,
            }}
            rounded='md'
            direction='column'
            align='center'
            w={{
              base: '100%',
              md: '60%'
            }}
          >
            <Img
              src={Success}
            />
            <Text as='h1'
              fontSize='4xl'
              textAlign='center'
            >Content Published</Text>
            <Text as='h2'
              fontSize='1xl'
              textAlign='center'
            >at <Text as={Link} to='/' target='_blank' color='blue.400' textDecoration='underline'>{finalContentUrl}</Text></Text>
            <Flex gap='3'>
              <Button
                size='sm'
                colorScheme='blue'
                mt='6'
                mb='3'
                variant='ghost'
                onClick={() => copyToClipboard(finalContentUrl)}
              >Copy Link</Button>
              <Button
                size='sm'
                colorScheme='blue'
                mt='6'
                mb='3'
                as={Link}
                to='/dashboard'
              >Go to dashboard</Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>}
      {!contentAdded && <Box
        bg="#edf3f8"
        _dark={{
          bg: "#111",
        }}
        p={10}
      >
        <Flex
          direction='column'
          align='center'
        >
          <Heading
            mb={10}
          >New Flashy Content ⚡</Heading>
          <chakra.form
            method="POST"
            shadow="base"
            rounded={[null, "md"]}
            overflow={{
              sm: "hidden",
            }}
            w={{
              base: '100%',
              md: '60%'
            }}
          >
            <Stack
              px={4}
              bg="white"
              _dark={{
                bg: "#141517",
              }}
              spacing={6}
              p={{
                base: 6,
              }}
            >
              <SimpleGrid columns={3} spacing={6}>
                <FormControl as={GridItem} colSpan={[3, 2]}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: "gray.50",
                    }}
                  >
                    Title
                  </FormLabel>
                  <InputGroup size="sm">
                    <Input
                      type="text"
                      focusBorderColor="brand.400"
                      rounded="md"
                      onChange={(e) => changeContent({ title: e.target.value })}
                    />
                  </InputGroup>
                </FormControl>
              </SimpleGrid>

              <div>
                <FormControl id="description" mt={1}>
                  <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color="gray.700"
                    _dark={{
                      color: "gray.50",
                    }}
                  >
                    Description
                  </FormLabel>
                  <Textarea
                    mt={1}
                    rows={3}
                    shadow="sm"
                    focusBorderColor="brand.400"
                    fontSize={{
                      sm: "sm",
                    }}
                    onChange={(e) => changeContent({ description: e.target.value })}
                  />
                  <FormHelperText>
                    Brief description for your content.
                  </FormHelperText>
                </FormControl>
              </div>

              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="type"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  Type
                </FormLabel>
                <Select
                  id="type"
                  name="type"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  size="sm"
                  w="full"
                  rounded="md"
                  onChange={(e) => {
                    changeContent({ type: e.target.value })
                    if (mediaUrl.length) {
                      deleteUrl(mediaUrl)
                      setMediaUrl('')
                      setIsUploadingFile(false)
                    }
                  }}
                >
                  <option value='image'>Image</option>
                  <option value='pdf'>PDF</option>
                  <option value='video'>Video</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  File Upload
                  {isUploadingFile && <CircularProgress value={progress} color='blue.400' size={35} ml={3}>
                    <CircularProgressLabel>{progress}%</CircularProgressLabel>
                  </CircularProgress>}
                </FormLabel>
                <Flex
                  mt={1}
                  justify="center"
                  px={6}
                  pt={5}
                  pb={6}
                  borderWidth={2}
                  _dark={{
                    color: "gray.500",
                  }}
                  borderStyle="dashed"
                  borderColor={isDragActive && "blue.300"}
                  rounded="md"
                  {...getRootProps({ className: 'dropzone' })}
                >
                  <Stack spacing={1} textAlign="center">
                    <Icon
                      mx="auto"
                      boxSize={12}
                      color="gray.400"
                      _dark={{
                        color: "gray.500",
                      }}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </Icon>
                    <Flex
                      fontSize="sm"
                      color="gray.600"
                      _dark={{
                        color: "gray.400",
                      }}
                      alignItems="baseline"
                    >
                      <chakra.label
                        htmlFor="file-upload"
                        cursor="pointer"
                        rounded="md"
                        fontSize="md"
                        color="brand.600"
                        _dark={{
                          color: "brand.200",
                        }}
                        pos="relative"
                        _hover={{
                          color: "brand.400",
                          _dark: {
                            color: "brand.300",
                          },
                        }}
                      >
                        <span>Upload a file</span>
                        <VisuallyHidden>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            {...getInputProps()}
                          />
                        </VisuallyHidden>
                      </chakra.label>
                      <Text pl={1}>or drag and drop</Text>
                    </Flex>
                    <Text
                      fontSize="xs"
                      color="gray.500"
                      _dark={{
                        color: "gray.50",
                      }}
                    >
                      PNG, JPG, GIF up to 10MB
                    </Text>
                  </Stack>
                </Flex>
                <Text py={2}>
                  {previewFile}
                </Text>
              </FormControl>
              <FormControl
                pb={5}
              >
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{
                    color: "gray.50",
                  }}
                >
                  Expiration Time
                </FormLabel>
                <Box>
                  <Slider defaultValue={25} min={0} max={75} step={25} onChange={value => changeContent(value, true)}>
                    <SliderMark value={1} mt='4' ml='-2.5' fontSize='sm'>
                      6h
                    </SliderMark>
                    <SliderMark value={25} mt='4' ml='-2.5' fontSize='sm'>
                      12h
                    </SliderMark>
                    <SliderMark value={50} mt='4' ml='-2.5' fontSize='sm'>
                      24h
                    </SliderMark>
                    <SliderMark value={75} mt='4' ml='-2.5' fontSize='sm'>
                      48h
                    </SliderMark>
                    <SliderTrack bg='blue.100'>
                      <Box position='relative' right={10} />
                      <SliderFilledTrack bg='blue.400' />
                    </SliderTrack>
                    <SliderThumb boxSize={6} shadow='md' />
                  </Slider>
                </Box>
              </FormControl>
            </Stack>
            <Box
              px={{
                base: 4,
                sm: 6,
              }}
              py={3}
              bg="gray.50"
              _dark={{
                bg: "#121212",
              }}
              textAlign="right"
            >
              <Button
                colorScheme="blue"
                variant='ghost'
                mr={2}
                onClick={() => redirectToPreview({ ...content, media: mediaUrl })}
              >
                Preview
              </Button>
              <Button
                colorScheme="blue"
                isLoading={isButtonDisabled}
                onClick={() => addContent({ ...content, media: mediaUrl })}
              >
                Save
              </Button>
            </Box>
          </chakra.form>
        </Flex>

        <Divider
          my="5"
          borderColor="gray.300"
          _dark={{
            borderColor: "whiteAlpha.300",
          }}
          visibility={{
            base: "hidden",
            sm: "visible",
          }}
        />
      </Box>}
    </>
  );
}

export default NewContent;