
import { initializeApp } from "firebase/app";

import { getAuth,GoogleAuthProvider } from '@firebase/auth'
import { getFirestore } from '@firebase/firestore'
import { getStorage } from "@firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyAFUUReANvdprTzYjYaMgugpw1AWWWTw9Y",
  authDomain: "breezyshare.firebaseapp.com",
  projectId: "breezyshare",
  storageBucket: "breezyshare.appspot.com",
  messagingSenderId: "1024907274649",
  appId: "1:1024907274649:web:bed45408e38646f453cb08"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const authProvider = new GoogleAuthProvider()
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
