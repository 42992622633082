import React from "react";
import {
  Box, Button, Flex, HStack, IconButton, Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  Icon,
  chakra,
  CloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Link as ChLink
} from "@chakra-ui/react";
import { HiOutlineSun, HiOutlineMoon, HiOutlineHome, HiMenu } from "react-icons/hi";
import { Link } from 'react-router-dom'
import Logo from './../assets/logo.png'
import { useViewportScroll } from "framer-motion";
import { UserContext } from './../Helper/UserContext'
import { signOut } from '@firebase/auth'
import { auth } from "../Helper/firebase-config";
import { useHistory, useLocation } from "react-router-dom";
const Navbar = () => {
  const { toggleColorMode: toggleMode } = useColorMode();
  const { setUser, setIsAuth, isAuth } = React.useContext(UserContext)
  const history = useHistory()
  const location = useLocation()
  // Functions Start
  const signout = async () => {
    await signOut(auth)
    setIsAuth(false)
    setUser(null)
    localStorage.removeItem('isAuth')
    localStorage.removeItem('user')
    history.replace('/')
    return
  }
  // Functions End
  const text = useColorModeValue("dark", "light");
  const closeButtonColor = useColorModeValue("gray", "#FFF");
  const SwitchIcon = useColorModeValue(HiOutlineMoon, HiOutlineSun);
  const bg = useColorModeValue("white", "gray.800");
  const ref = React.useRef(null);
  const [y, setY] = React.useState(0);
  const height = ref.current ? ref.current.getBoundingClientRect() : 0;
  const { scrollY } = useViewportScroll();
  React.useEffect(() => {
    return scrollY.onChange(() => setY(scrollY.get()));
  }, [scrollY]);
  const mobileNav = useDisclosure();


  const MobileNavContent = (
    <VStack
      pos="absolute"
      top={0}
      left={0}
      right={0}
      display={mobileNav.isOpen ? "flex" : "none"}
      flexDirection="column"
      p={2}
      pb={4}
      m={2}
      bg={bg}
      spacing={3}
      rounded="sm"
      shadow="sm"
      zIndex={1000}
    >
      <CloseButton
        aria-label="Close menu"
        justifySelf="self-start"
        onClick={mobileNav.onClose}
        color={closeButtonColor}
      />
      {!isAuth ? <>
        {location.pathname != '/auth' && <>
          <Button variant="ghost"
            colorScheme='gray'
            as={ChLink}
            href='#how'
          >
            How it works
          </Button>
        </>}
        <Button colorScheme="blue" variant="solid"
          as={Link}
          to='/auth'
        >
          Sign in
        </Button>
      </> : <>
        <Button colorScheme="blue" variant="ghost"
          as={Link}
          to='/dashboard'
        >
          Dashboard
        </Button>
        <Button colorScheme="red" variant="solid"
          onClick={() => signout()}
        >
          Sign Out
        </Button>
      </>}
    </VStack>
  );
  return (
    <chakra.header
      ref={ref}
      shadow={y > height ? "sm" : undefined}
      transition="box-shadow 0.2s"
      bg={bg}
      borderTop="6px solid"
      borderTopColor="brand.400"
      w="full"
      overflowY="hidden"
      borderBottomWidth={2}
      color="gray.200"
      _dark={{
        color: "gray.900",
      }}
    >
      <chakra.div h="4.5rem" mx="auto" maxW="1200px">
        <Flex
          w="full"
          h="full"
          px="6"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex align="flex-start">
            <Link to="/">
              <HStack>
                <Image src={Logo} />
              </HStack>
            </Link>
          </Flex>
          <Flex justify="flex-end" align="center" color="gray.400">
            <HStack
              spacing="5"
              display={{
                base: "none",
                md: "flex",
              }}
            >
              {!isAuth ? <>
                {location.pathname != '/auth' && <>
                  <Button colorScheme="gray" variant="ghost" size="sm"
                    as={ChLink}
                    href='#how'
                    textDecoration='none'
                  >
                    How it works
                  </Button>
                </>}
                <Button colorScheme="blue" variant="solid" size="sm"
                  as={Link}
                  to='/auth'
                >
                  Sign in
                </Button>
              </> : <>
                <Button colorScheme="blue" variant="ghost" size="sm"
                  as={Link}
                  to='/dashboard'
                >
                  Dashboard
                </Button>
                <Button colorScheme="red" variant="solid" size="sm"
                  onClick={() => signout()}
                >
                  Sign Out
                </Button>
              </>}
            </HStack>
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{
                base: "0",
                md: "3",
              }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            <IconButton
              display={{
                base: "flex",
                md: "none",
              }}
              aria-label="Open menu"
              fontSize="20px"
              color="gray.800"
              _dark={{
                color: "inherit",
              }}
              variant="ghost"
              icon={<HiMenu />}
              onClick={mobileNav.onOpen}
            />
          </Flex>
        </Flex>
        {MobileNavContent}
      </chakra.div>
    </chakra.header>
  );
};

export default Navbar;