import React from "react";
import {
  Box, Flex, Heading, VStack,
  Text, Image, HStack, Spinner, Img, Button
} from "@chakra-ui/react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import TimeUnit from "./TimeUnit";
import Countdown from 'react-countdown';
import { withRouter, useParams, Link } from 'react-router-dom'
import {
  collection, orderBy, query, limit, getDocs, startAfter, endBefore,
  addDoc, Timestamp, where, startAt, doc, getDoc, increment, updateDoc
} from "firebase/firestore";
import { db } from './../../Helper/firebase-config'
import Expired from './../../assets/expired.svg'
import NoContent from './../../assets/NoContent.svg'
const Content = () => {
  const { contentId } = useParams()
  const [content, setContent] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const fetchContent = async () => {
    const collectionRef = collection(db, 'media');
    let q = query(collectionRef, where('contentId', '==', contentId), limit(1))
    let docData = await getDocs(q)
    if (!docData.empty) {
      let data = docData.docs[0].data()
      let docId = docData.docs[0].id
      let docRef = doc(db, 'media', docId)
      await updateDoc(docRef, {
        visitors: increment(1)
      });
      setContent(data)
    }
    setIsLoading(false)
    return
  }
  React.useEffect(() => {
    fetchContent()
  }, [])

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return <HStack mt={4}>
      <TimeUnit value={hours} type='HOURS' />
      <TimeUnit value={minutes} type='MINUTES' />
      <TimeUnit value={seconds} type='SECONDS' />
    </HStack>;
  };

  const FallBackMessage = ({ type, message }) => {
    return <>
      <Box
        bg="#edf3f8"
        _dark={{
          bg: "#111",
        }}
        p={10}
      >
        <Flex
          direction='column'
          align='center'
        >
          <Flex
            bg="white"
            _dark={{
              bg: "#141517",
            }}
            p={6}
            rounded='md'
            direction='column'
            align='center'
            w={{
              base: '100%',
              md: '60%'
            }}
          >
            <Img
              src={type == 'noContent' ? NoContent : Expired}
            />
            <Text as='h1'
              fontSize='4xl'
              textAlign='center'
            >{message}</Text>
            <Flex gap='3'>
              <Button
                size='sm'
                colorScheme='blue'
                mt='6'
                mb='3'
                as={Link}
                to='/'
              >Go Home</Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </>
  }
  return (<>
    {isLoading ?
      <Flex justify='center' py={4}>
        <Spinner />
      </Flex>
      : <>
        {!content ? <FallBackMessage
          type='noContent'
          message='No content found!'
        /> : <>
          {new Date() > new Date(content.expired_at) ? <FallBackMessage
            type='expired'
            message='Content Expired'
          /> : <>
            <Flex
              direction='column'
              align='center'
              p={6}
            >
              <VStack
                w={{
                  base: '100%',
                  md: '60%'
                }}
                mb={10}
              >
                <VStack
                  my={10}
                >
                  <Heading
                    as='h1'
                    textAlign='center'
                  >This content will disappear in</Heading>
                  <Box>
                    <Countdown
                      date={new Date(content.expired_at)}
                      renderer={renderer}
                      zeroPadTime={2}
                    />
                  </Box>
                </VStack>
                <VStack>
                  <Box>
                    {content.type == 'image' && <>
                      <Zoom>
                        <Image
                          src={content.media}
                        />
                      </Zoom>
                    </>}
                    {content.type == 'pdf' && <>
                      <iframe src={content.media}
                        width="800"
                        height="500">
                      </iframe>
                    </>}
                    {content.type == 'video' && <>
                      <video width="320" height="240" autoplay controls>
                        <source src={content.media} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </>}
                  </Box>
                </VStack>
                <VStack>
                  <Box
                    textAlign='center'
                  >
                    <Text fontSize='3xl'>{content.title}</Text>
                  </Box>
                  <Box>
                    <Text>
                      {content.description}
                    </Text>
                  </Box>
                </VStack>
              </VStack>
            </Flex>
          </>}

        </>}
      </>}
  </>);
}

export default withRouter(Content);