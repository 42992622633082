import React from "react";
import {
  Container, Flex, Button,
  Spinner, Img, Text, Divider, IconButton, ButtonGroup
} from "@chakra-ui/react";
import Welcome from "./Welcome";
import Filters from "./Filters";
import ContentItem from "./ContentItem";
import { Link } from 'react-router-dom'
import {
  collection, orderBy, query, limit, getDocs, startAfter, endBefore, where, startAt, deleteDoc, doc, limitToLast
} from "firebase/firestore";
import { db } from './../../Helper/firebase-config'
import { UserContext } from './../../Helper/UserContext'
import Empty from './../../assets/empty.png'
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Dashboard = () => {

  const { user } = React.useContext(UserContext)
  const [isContentLoading, setIsContentLoading] = React.useState(true)
  const [userContent, setUserContent] = React.useState([])
  const [cursorHead, setCursorHead] = React.useState(null)
  const [cursorTail, setCursorTail] = React.useState(null)
  const [filter, setFilter] = React.useState({
    date: 'desc',
    visitors: 'desc'
  })

  const fetchContent = async (filter, cursor, type = 'next') => {
    setIsContentLoading(true)
    const pageSize = 7
    const collectionRef = collection(db, 'media');
    let q;
    if (cursor != null) {
      if (type == 'next') {
        q = query(collectionRef,
          where('owner', '==', user.uid),
          orderBy("created_at", filter.date),
          orderBy("visitors", filter.visitors),
          startAfter(cursor),
          limit(pageSize));
      } else {
        q = query(collectionRef,
          where('owner', '==', user.uid),
          orderBy("created_at", filter.date),
          orderBy("visitors", filter.visitors),
          endBefore(cursor),
          limit(pageSize));
      }
    } else {
      q = query(collectionRef,
        where('owner', '==', user.uid),
        orderBy("created_at", filter.date),
        orderBy("visitors", filter.visitors),
        limit(pageSize));
    }

    const querySnapshot = await getDocs(q)
    if (!querySnapshot.empty) {
      let contentSnapshot = querySnapshot.docs
      let content = []
      contentSnapshot.forEach(media => content.push({
        ...media.data(),
        created_at: media.data().created_at.toDate().toString(),
        id: media.id
      }))
      setUserContent(content)
      setCursorHead(querySnapshot.docs[0])
      setCursorTail(querySnapshot.docs[querySnapshot.docs.length - 1])
    } else {
      if (cursor == null) setUserContent([])
    }
    setIsContentLoading(false)
    return
  }


  React.useEffect(() => {
    fetchContent(filter, null, 'next')
  }, [filter])
  return (<Container maxW='1200px'>
    <Welcome />
    <Flex justify='space-between'
      align='flex-end'
    >
      <Filters
        setFilter={setFilter}
        filter={filter}
      />
      <Button colorScheme='blue' as={Link} to='/new-content'>
        New Content
      </Button>
    </Flex>
    <Divider py={3} />
    <Flex mt={10}
      align='center'
      direction='column'
    >
      {isContentLoading ? <Spinner my={7} /> : <>
        {!userContent.length ? <>
          <Img
            src={Empty}
          />
          <Text fontSize='2xl' textAlign='center'
            mt={2}
            mb={4}
          >No content yet!</Text>
        </> : <>
          {userContent.map(content => {
            return <ContentItem
              key={content.contentId}
              filter={filter}
              fetchContent={fetchContent}
              content={content}
            />
          })}
          <Flex justify='center' py={5}>
            <ButtonGroup gap='4'>
              <IconButton
                onClick={() => fetchContent(filter, cursorHead, 'back')}
                colorScheme="blue"
                variant='ghost'
                icon={<HiChevronLeft size={30} />}
              />
              <IconButton
                onClick={() => fetchContent(filter, cursorTail, 'next')}
                colorScheme="blue"
                variant='ghost'
                icon={<HiChevronRight size={30} />}
              />
            </ButtonGroup>
          </Flex>
        </>}
      </>}
    </Flex>
  </Container>);
}

export default Dashboard;