import React from "react";
import {
  Text,
  Flex, Box, chakra, HStack, Button, Image, calc
} from "@chakra-ui/react";
import { Link } from 'react-router-dom'
import { UserContext } from "../../Helper/UserContext";
const Hero = () => {
  const { isAuth } = React.useContext(UserContext)
  return (
    <Flex px={4} pt={5} pb={{
      base: 10,
      md: 32
    }} mx="auto" position='relative'
      style={{
        minHeight: 'calc(100vh - 72px)'
      }}
      bg="#edf3f8"
      _dark={{
        bg: "#3e3e3e",
      }}
      direction={{
        base: 'column',
        md: 'row'
      }}
    >
      <Box
        mx="auto"
        display='flex'
        flexDirection='column'
        justifyContent='center'
      >
        <chakra.p
          mb={2}
          fontSize="xs"
          fontWeight="semibold"
          letterSpacing="wide"
          color="gray.400"
          textTransform="uppercase"
        >
          For Content Creators
        </chakra.p>
        <chakra.h1
          mb={3}
          fontSize={{
            base: "5xl",
          }}
          fontWeight="bold"
          lineHeight="shorter"
          color="gray.900"
          _dark={{
            color: "white",
          }}
          textAlign='center'
        >
          Effortless Ephemeral Sharing at Your Fingertips
        </chakra.h1>
        <chakra.p
          mb={5}
          color="gray.500"
          fontSize={{
            md: "3xl",
          }}
          textAlign='center'
        >
          Craft temporary images, PDFs, and videos <br />that disappear like <Text fontStyle='italic'
            display='inline' id='magic'>magic</Text>
        </chakra.p>
        <HStack
          justify='center'
        >
          <Button
            w={{
              base: "full",
              sm: "auto",
            }}
            mb={{
              base: 2,
              sm: 0,
            }}
            size="lg"
            cursor="pointer"
            colorScheme='blue'
            as={Link}
            to={!isAuth ? '/auth' : '/dashboard'}
          >
            Start Creating
          </Button>
        </HStack>
      </Box>
      <Image
        src={require('./../../assets/hero.png')}
      />
    </Flex>

  );
}

export default Hero;