import React from "react";
import { Text, Box } from "@chakra-ui/react";
import { UserContext } from "../../Helper/UserContext";
const Welcome = () => {
    const { user } = React.useContext(UserContext)
    let name = user.displayName
    return (<Box>
        <Text
            fontSize='5xl'
        >Hi {`${name[0].toUpperCase()}${name.split(' ')[0].slice(1)}`}</Text>
        <Text
            fontSize='3xl'
        >Your Breezy Content ⏳</Text>
    </Box>);
}

export default Welcome;