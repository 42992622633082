import React from 'react'
import { Box, Flex, Img, Text,Button } from '@chakra-ui/react';
import NotFoundImage from './../assets/NotFoundImage.png'
import { Link } from 'react-router-dom'
const NotFound = () => {
    return <>
        <Box
            bg="#edf3f8"
            _dark={{
                bg: "#111",
            }}
            p={{
                base: 2,
                md: 10
            }}
        >
            <Flex
                direction='column'
                align='center'
            >
                <Flex
                    bg="white"
                    _dark={{
                        bg: "#141517",
                    }}
                    p={{
                        base: 6,
                    }}
                    rounded='md'
                    direction='column'
                    align='center'
                    w={{
                        base: '100%',
                        md: '60%'
                    }}

                >
                    <Img
                        src={NotFoundImage}
                        w={300}
                    />
                    <Text as='h1' fontSize='2xl' textAlign='center'>Page not found.</Text>
                    <Button
                        size='sm'
                        colorScheme='blue'
                        mt='6'
                        mb='3'
                        as={Link}
                        to='/'
                    >Go Home</Button>
                </Flex>
            </Flex>
        </Box>
    </>
}

export default NotFound;