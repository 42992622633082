import { Box, Container, Heading } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { UserContext } from './Helper/UserContext';
import { auth } from './Helper/firebase-config'
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Auth from './Components/Auth';
import LandingPage from './Components/LandingPage';
import Dashboard from './Components/Dashboard';
import NewContent from './Components/NewContent';
import Preview from './Components/Preview';
import Content from './Components/Content';
import TermsOfService from './Components/TermsOfService';
import PrivacyPolicy from './Components/PrivacyPolicy';
import NotFound from './Components/404';
import {Helmet} from "react-helmet";

const App = () => {
  const [isAuth, setIsAuth] = React.useState(localStorage.getItem('isAuth'))
  const [user, setUser] = React.useState(JSON.parse(localStorage.getItem('user')))
  const globalState = {
    isAuth,setIsAuth,
    user,setUser
  }

const PageTitleChanger = ({title}) => {
  return <Helmet>
  <title>BreezyShare - {title}</title>
</Helmet>
}
  return <Box>
    <Router>
      <UserContext.Provider value={{...globalState}}>
        <Navbar />
        <Switch>
          <Route exact path="/">
            <PageTitleChanger title='Effortless Ephemeral Content Creation and Sharing.'/>
        <LandingPage />
          </Route>
          
          {!isAuth && <Route path="/auth">
            <PageTitleChanger title='Auth'/>
           <Auth />
          </Route>}
          {isAuth && <Route path="/dashboard">
            <PageTitleChanger title='Dashboard'/>
          <Dashboard />
          </Route>}
          {isAuth && <Route path="/new-content">
            <PageTitleChanger title='New content'/>
          <NewContent />
          </Route>}
         {isAuth && <Route path="/preview">
            <PageTitleChanger title='Preview'/>
          <Preview />
          </Route>}
          <Route path="/content/:contentId">
            <PageTitleChanger title='Content view'/>
         <Content />
          </Route>
          <Route path="/terms-of-service">
            <PageTitleChanger title='Terms of service'/>
         <TermsOfService />
          </Route>
          <Route path="/privacy-policy">
            <PageTitleChanger title='Privacy policy'/>
         <PrivacyPolicy />
          </Route>
          <Route path="*">
            <PageTitleChanger title='Error 404'/>
            <NotFound />
          </Route>
        </Switch>
        <Footer />
      </UserContext.Provider>
    </Router>
  </Box>;
}

export default App;