import React from "react";
import {
    VStack,
    Text
} from "@chakra-ui/react";
const TimeUnit = ({ type, value }) => {
    return (<VStack
        shadow='md'
        bgColor='blue.300'
        rounded='md'
        py={3}
        px={6}
        spacing={0}
    >
        <Text
            fontSize='4xl'
            color='#FFF'
            fontWeight='bold'
        >{value}</Text>
        <Text fontSize='small'
            fontWeight='bold'
            color="#FFF"
        >{type}</Text>
    </VStack>);
}

export default TimeUnit;