import React from "react";
import {
  Box, Button, Flex, HStack, IconButton, Text, VStack,
  useToast
} from "@chakra-ui/react";
import { HiOutlineTrash, HiDocumentDuplicate } from "react-icons/hi";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../Helper/firebase-config";
const ContentItem = ({
  content,
  fetchContent,
  filter
}) => {

  const deleteDocument = async (id) => {
    let docRef = doc(db, 'media', id)
    deleteDoc(docRef)
      .then(() => {
        fetchContent(filter, null, 'next')
      })
    return
  }
  const toast = useToast()
  let contentUrl = `${window.location.origin}/content/${content.contentId}`
  function copyToClipboard(text) {
    // Create a textarea element to hold the text
    const textarea = document.createElement('textarea');

    // Set the text content to the provided text
    textarea.value = text;

    // Make the textarea hidden
    textarea.style.position = 'fixed';
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.width = '1px';
    textarea.style.height = '1px';
    textarea.style.opacity = 0;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Select the text in the textarea
    textarea.select();

    try {
      // Execute the copy command
      document.execCommand('copy');
      toast({
        title: 'Copied to clipboard!',
        status: 'success',
        duration: 3500,
        isClosable: true,
      })
    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    } finally {
      // Remove the textarea from the document
      document.body.removeChild(textarea);
    }
  }
  const formatDate = (date) => {
    let objectDate = new Date(date)
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: "2-digit",
      minute: "2-digit"
    };
    return objectDate.toLocaleDateString('en-GB', options);
  }
  return (
    <Box w={{
      base: '100%',
      md: '70%'
    }}
      bg="#edf3f8"
      _dark={{
        bg: "#111",
      }}
      rounded='md'
      px={10}
      py={4}
      mb={10}
      shadow='lg'
    >
      <Flex
        justify='space-between'
        align='center'
      >
        <Text
          fontSize='2xl'
        >{content.title}</Text>
        <IconButton
          onClick={() => deleteDocument(content.id)}
          icon={<HiOutlineTrash />}
          colorScheme='blue'
          variant='ghost'
        />
      </Flex>
      <Box my={7}>
        {content.description}
      </Box>
      <HStack>
        <VStack>
          <Text
            color='gray.500'
          >Type</Text>
          <Text fontWeight='bold'>{`${content.type[0].toUpperCase()}${content.type.slice(1)}`}</Text>
        </VStack>
        <VStack>
          <Text
            color='gray.500'
          >Status</Text>
          <Text fontWeight='bold'>
            {new Date() > new Date(content.expired_at) ? 'Expired' : 'Active'}
          </Text>
        </VStack>
        <VStack>
          <Text
            color='gray.500'
          >Visitors</Text>
          <Text fontWeight='bold'>{content.visitors}</Text>
        </VStack>
        <VStack>
          <Text
            color='gray.500'
          >Created at</Text>
          <Text fontWeight='bold'>{formatDate(content.created_at)}</Text>
        </VStack>
      </HStack>
      <Box>
        <HStack my={7}>
          <Button
            colorScheme='blue'
            onClick={() => copyToClipboard(contentUrl)}
            variant='ghost'
            leftIcon={<HiDocumentDuplicate />}
          >Get link</Button>
        </HStack>
      </Box>
    </Box>
  );
}

export default ContentItem;