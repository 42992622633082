import { Box } from "@chakra-ui/react";
import Hero from "./Hero";
import HowItWorks from "./HowItWorks";
import CTA from "./CTA";

const LandingPage = () => {
    return (<Box>
        <Hero />
        <HowItWorks />
        <CTA />
    </Box>);
}

export default LandingPage;