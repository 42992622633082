import React from "react";
import {
    Box,
    Button,
    Flex,
    Text
} from "@chakra-ui/react";
import { FaGoogle } from "react-icons/fa";
import { auth, authProvider } from "../Helper/firebase-config";
import { signInWithPopup, signOut } from '@firebase/auth'
import { UserContext } from "../Helper/UserContext";
import { useHistory } from 'react-router-dom'
const Auth = () => {
    const { setUser, setIsAuth } = React.useContext(UserContext)
    const history = useHistory()
    
    const saveCredentials = (isAuth, user) => {
        localStorage.setItem('isAuth', isAuth)
        localStorage.setItem('user', user)
        return
    }

    const authWithGoogle = () => {
        signInWithPopup(auth, authProvider)
            .then(res => {
                setUser(res.user)
                setIsAuth(true)
                saveCredentials(true, JSON.stringify(res.user))
                history.replace('/dashboard')
            })
            .catch(err => console.log(err))
        return
    }

    return (
        <Flex
            style={{
                minHeight: 'calc(100vh - 160px)'
            }}
            justify='center'
            align='center'
        >
            <Flex
                justify='center'
                align='center'
                direction='column'
                py={10}
                px={20}
                shadow='lg'
            >
                <Box mb={5}>
                    <Text
                        textAlign='center'
                        fontSize='5xl'
                    >Login</Text>
                </Box>
                <Button leftIcon={<FaGoogle />} colorScheme='blue'
                    onClick={() => authWithGoogle()}
                >
                    Login with Google
                </Button>
            </Flex>
        </Flex>
    );
}

export default Auth;