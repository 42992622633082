import React from "react";
import {
  Box, SimpleGrid, chakra, Flex,
  Image
} from "@chakra-ui/react";
import Form from './../../assets/Form.png'
import Counter from './../../assets/Counter.png'
import Share from './../../assets/Share.png'
import Vanish from './../../assets/Vanish.png'
const HowItWorks = () => {
  return (
    <Flex
      direction='column'
      justify='center'
      id='how'
    >
      <chakra.h2
        fontSize={{
          base: "2xl",
          md: "5xl",
        }}
        fontWeight="bold"
        textAlign={{
          base: "center",
        }}
        color="gray.900"
        _dark={{
          color: "gray.400",
        }}
        lineHeight={{
          md: "shorter",
        }}
        my={10}
      >
        How BreezyShare works ✨
      </chakra.h2>
      <Flex
        //   bg="#edf3f8"
        _dark={{
          bg: "#3e3e3e",
        }}
        w="full"
        justifyContent="center"
        alignItems="center"
      // pos="absolute"
      >
        <Box
          shadow="xl"
          bg="white"
          _dark={{
            bg: "gray.800",
          }}
          px={8}
          py={{
            base: 0,
            md: 20
          }}
          mx="auto"
        >

          <SimpleGrid
            alignItems="center"
            columns={{
              base: 1,
              md: 2,
            }}
            flexDirection="column-reverse"
            mb={24}
            spacingY={{
              base: 10,
              md: 32,
            }}
            spacingX={{
              base: 10,
              md: 24,
            }}
          >
            <Box
              order={{
                base: "initial",
                md: 2,
              }}
            >
              <chakra.h2
                mb={4}
                fontSize={{
                  base: "2xl",
                  md: "4xl",
                }}
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign={{
                  base: "center",
                  md: "left",
                }}
                color="gray.900"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight={{
                  md: "shorter",
                }}
                textShadow="2px 0 currentcolor"
              >
                Create Your Content
              </chakra.h2>
              <chakra.p
                mb={5}
                textAlign={{
                  base: "center",
                  sm: "left",
                }}
                color="gray.600"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "lg",
                }}
              >
                Start by effortlessly crafting your ephemeral content—whether it's a quick image, a PDF, or a snappy video—using the intuitive creation tools on BreezyShare.
              </chakra.p>
            </Box>
            <Box
              w="full"
              h="full"
              display='flex'
              justifyContent='center'
            >
              <Image
                src={Form}
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            alignItems="start"
            columns={{
              base: 1,
              md: 2,
            }}
            mb={24}
            spacingY={{
              base: 10,
              md: 32,
            }}
            spacingX={{
              base: 10,
              md: 24,
            }}
          >
            <Box>
              <chakra.h2
                mb={4}
                fontSize={{
                  base: "2xl",
                  md: "4xl",
                }}
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign={{
                  base: "center",
                  md: "left",
                }}
                color="gray.900"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight={{
                  md: "shorter",
                }}
                textShadow="2px 0 currentcolor"
              >
                Set Expiration Time
              </chakra.h2>
              <chakra.p
                mb={5}
                textAlign={{
                  base: "center",
                  sm: "left",
                }}
                color="gray.600"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "lg",
                }}
              >
                Customize the lifespan of your shared content effortlessly. Choose the expiration time that suits your needs, whether it's a few minutes or a few hours, and let us take care of the rest.
              </chakra.p>
            </Box>
            <Box
              w="full"
              h="full"
              display='flex'
              justifyContent='center'
            >
              <Image
                src={Counter}
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            alignItems="center"
            columns={{
              base: 1,
              md: 2,
            }}
            flexDirection="column-reverse"
            mb={24}
            spacingY={{
              base: 10,
              md: 32,
            }}
            spacingX={{
              base: 10,
              md: 24,
            }}
          >
            <Box
              order={{
                base: "initial",
                md: 2,
              }}
            >
              <chakra.h2
                mb={4}
                fontSize={{
                  base: "2xl",
                  md: "4xl",
                }}
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign={{
                  base: "center",
                  md: "left",
                }}
                color="gray.900"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight={{
                  md: "shorter",
                }}
                textShadow="2px 0 currentcolor"
              >
                Share with a click
              </chakra.h2>
              <chakra.p
                mb={5}
                textAlign={{
                  base: "center",
                  sm: "left",
                }}
                color="gray.600"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "lg",
                }}
              >
                Once your content is ready and organized, sharing is a breeze. Obtain a unique link or code, and share your creations seamlessly through social media, messages, or any platform you prefer.
              </chakra.p>
            </Box>
            <Box
              w="full"
              h="full"
              display='flex'
              justifyContent='center'
            >
              <Image
                src={Share}
              />
            </Box>
          </SimpleGrid>
          <SimpleGrid
            alignItems="start"
            columns={{
              base: 1,
              md: 2,
            }}
            mb={24}
            spacingY={{
              base: 10,
              md: 32,
            }}
            spacingX={{
              base: 10,
              md: 24,
            }}
          >
            <Box>
              <chakra.h2
                mb={4}
                fontSize={{
                  base: "2xl",
                  md: "4xl",
                }}
                fontWeight="extrabold"
                letterSpacing="tight"
                textAlign={{
                  base: "center",
                  md: "left",
                }}
                color="gray.900"
                _dark={{
                  color: "gray.400",
                }}
                lineHeight={{
                  md: "shorter",
                }}
                textShadow="2px 0 currentcolor"
              >
                Watch It Vanish
              </chakra.h2>
              <chakra.p
                mb={5}
                textAlign={{
                  base: "center",
                  sm: "left",
                }}
                color="gray.600"
                _dark={{
                  color: "gray.400",
                }}
                fontSize={{
                  md: "lg",
                }}
              >
                Embrace the magic of BreezyShare as your shared content gracefully fades away according to the set expiration time. It's that simple – create, share, and watch it vanish, leaving behind a breeze of digital transience.
              </chakra.p>
            </Box>
            <Box
              w="full"
              h="full"
              display='flex'
              justifyContent='center'
            >
              <Image
                src={Vanish}
              />
            </Box>
          </SimpleGrid>
        </Box>
      </Flex>
    </Flex>

  );
}

export default HowItWorks;