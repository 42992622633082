import React from "react";
import {
  Box, Flex, Heading, VStack,
  Text, Image, HStack
} from "@chakra-ui/react";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import TimeUnit from "./TimeUnit";
import Countdown from 'react-countdown';

const Preview = () => {
  let searchParams = new URL(document.location).searchParams;
  let title = searchParams.get('title')
  let description = searchParams.get('description')
  let media = `${searchParams.get('media')}&token=${searchParams.get('token')}`
  media = media.replace(/media\//g, "media%2F")
  let expiration = searchParams.get('expiration')
  let type = searchParams.get('type')

  const renderer = ({ hours, minutes, seconds, completed }) => {
    return <HStack mt={4}>
      <TimeUnit value={hours} type='HOURS' />
      <TimeUnit value={minutes} type='MINUTES' />
      <TimeUnit value={seconds} type='SECONDS' />
    </HStack>;
  };

  const toMilliseconds = (hrs, min, sec) => (hrs * 60 * 60 + min * 60 + sec) * 1000;
  let timeOffset = toMilliseconds(parseInt(expiration), 0, 0)
  return (<Flex
    direction='column'
    align='center'
  >
    <VStack
      w={{
        base: '100%',
        md: '60%'
      }}
      mb={10}
    >
      <VStack
        my={10}
      >
        <Heading
          as='h1'
        >This content will disappear in</Heading>
        <Box>
          <Countdown
            date={Date.now() + timeOffset}
            renderer={renderer}
            zeroPadTime={2}
          />
        </Box>
      </VStack>
      <VStack>
        <Box>
          {type == 'image' && <>
            <Zoom>
              <Image
                src={media}
              />
            </Zoom>
          </>}
          {type == 'pdf' && <>
            <iframe src={media}
              width="800"
              height="500">
            </iframe>
          </>}
          {type == 'video' && <>
            <video width="320" height="240" autoplay controls>
              <source src={media} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </>}
        </Box>
      </VStack>
      <VStack>
        <Box
          textAlign='center'
        >
          <Text fontSize='3xl'>{title}</Text>
          <Text fontSize='sm'>By Othmane Bejja</Text>
        </Box>
        <Box>
          <Text>
            {description}
          </Text>
        </Box>
      </VStack>
    </VStack>
  </Flex>);
}

export default Preview;